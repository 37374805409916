import { PureComponent } from "react";
// eslint-disable-next-line no-restricted-imports
import isEqual from "lodash/isEqual";
import { useParams, useNavigate, Navigate } from "react-router-dom";
import { useIntl, defineMessages, FormattedMessage } from "react-intl";

import { overflowIsTurnedOff } from "common/notary/util";
import { formattedPropertyAddress } from "util/mortgage/transaction";
import { AuthTypes, Feature, OrganizationTransactionContactRoleType } from "graphql_globals";
import { captureException } from "util/exception";
import { UNASSIGNED as UNASSIGNED_CLOSER } from "common/transactions/closer_assignment";
import { usePermissions } from "common/core/current_user_role";
import LoadingIndicator from "common/core/loading_indicator";
import TitleTransactionEditQuery from "title_portal/transactions/graphql/queries/title_edit_query.graphql";
import UpdateTitleOrgTransactionMutation from "title_portal/transactions/graphql/mutations/update_title_org_transaction_mutation.graphql";
import TrackDocumentBundleAccessedMutation from "common/document_bundle/track_document_bundle_accessed_mutation.graphql";
import UpsertDocumentBundleInstructionMutation from "common/transactions/graphql/mutations/upsert_document_bundle_instruction_mutation.graphql";
import DeleteDocumentBundleInstructionMutation from "common/transactions/graphql/mutations/delete_document_bundle_instruction_mutation.graphql";
import SendOrganizationTransactionMutation from "common/transactions/graphql/mutations/send_organization_transaction_mutation.graphql";
import DeleteOrganizationTranasctionCustomerMutation from "common/transactions/graphql/mutations/delete_organization_transaction_customer_mutation.graphql";
import SendOrganizationTransactionToClosingOpsMutation from "common/transaction_creation/v3/send_organization_transaction_to_closing_ops_mutation.graphql";
import { addError } from "redux/actions/errors";
import store from "redux/store";
import { segmentTrack } from "util/segment";
import { useMutation } from "util/graphql";
import { QueryWithLoading, isGraphQLError } from "util/graphql/query";
import { useRawMutation } from "util/graphql/mutation";
import { encodeCustomerSigners } from "util/customer_signers";
import { transactionEditRouteV3, TRANSACTION_PATH, transactionDetailsRoute } from "util/routes";
import { isHybridTransactionType } from "common/mortgage/transactions/utils";
import { DEFAULT_TRANSACTION_NAME } from "constants/transaction";
import { ERROR_TYPES } from "constants/errors";
import { EVENT } from "constants/analytics";
import { useActiveOrganization } from "common/account/active_organization";
import { newPathWithPreservedSearchParams } from "util/location";
import {
  serializeSigningSchedule,
  deserializeSigningSchedule,
} from "common/details/meeting/notary_details/items/signing_schedule_util";
import {
  useTransactionCreationV3,
  TRANSACTION_CREATION_V3_OPT_OUT_TAG,
} from "common/transaction_creation/v3/detection";
import { TransactionErrorRedirect } from "common/transaction_creation/v3/form";

import TransactionEditForm, { alternativelyHandledPayment } from ".";
import InvoiceModal from "./invoice_modal";

const messages = defineMessages({
  updateError: {
    id: "29cb861d-8a94-4292-87ba-9ee477a58abf",
    defaultMessage: "There was an issue updating the transaction.",
  },
  genericError: {
    id: "9c6f0be5-548b-4735-bcbb-407d262325fe",
    defaultMessage: "Something went wrong",
  },
});

export const TITLE_EDIT_QUERY_USER_TAG_LIST = [TRANSACTION_CREATION_V3_OPT_OUT_TAG];

class TransactionContentContainer extends PureComponent {
  constructor(props) {
    super(props);
    const {
      transaction,
      transaction: { pointsOfContact, customerSigners, transactionType },
      viewer: { user },
      organization,
      placeAnOrderEnabled,
      permissions: { hasPermissionFor },
    } = props;

    const usersOrgCreatedTransaction =
      organization.id === transaction.organization.id ||
      Boolean(
        organization.subsidiaryOrganizations.find((o) => o.id === transaction.organization.id),
      );

    const transactionName = transaction.name === DEFAULT_TRANSACTION_NAME ? null : transaction.name;
    const userTimezone = user?.timezone;

    // Also make sure that existing transactions from v1 can be loaded in v2
    const signingScheduleType = transaction.signingScheduleType;

    const {
      activationDate,
      activationTimezone,
      expirationDate,
      expirationTimezone,
      notaryMeetingTime,
      notaryMeetingTimezone,
    } = deserializeSigningSchedule({
      activationTimezone: transaction.activationTimezone,
      activationTime: transaction.activationTime,
      expiryTimezone: transaction.expiryTimezone,
      expiry: transaction.expiry,
      notaryMeetingTimezone: transaction.notaryMeetingTimezone,
      notaryMeetingTime: transaction.notaryMeetingTime,
      signingScheduleType,
      userTimezone,
    });

    // We only want to add the current lender user as a point of contact for place an order and if there are no
    // other points of contact already present.
    const initialPointsOfContact =
      placeAnOrderEnabled &&
      !hasPermissionFor("manageOpenOrders") &&
      usersOrgCreatedTransaction &&
      pointsOfContact &&
      pointsOfContact.length === 0
        ? [
            {
              firstName: user.firstName,
              lastName: user.lastName,
              email: user.email,
              role: OrganizationTransactionContactRoleType.TITLE_AGENT,
              shownToSigner: true,
            },
          ]
        : pointsOfContact;

    this.initTransactionData = {
      transactionName,
      transactionType,
      secondaryId: transaction.secondaryIdRequired,
      customerNote: transaction.message,
      subjectLine: transaction.messageSubject,
      emailSignature: transaction.messageSignature,
      recallReason: transaction.recallReason,
      isRecalled: transaction.recalled,
      recordingLocation: transaction.recordingLocation?.id,
      // Convert the title underwriter data into something usable by the select input
      titleUnderwriter: transaction?.titleUnderwriter?.id,
      fileNumber: transaction.fileNumber,
      loanNumber: transaction.loanNumber,
      entityName: transaction.entityName,
      isTransactionForEntity: Boolean(transaction.entityName),
      smsAuthenticationRequired: transaction.authenticationRequirement === AuthTypes.SMS,
      activationTimezone,
      expirationTimezone,
      notaryMeetingTimezone,
      activationDate,
      expirationDate,
      notaryMeetingTime,
      closerAssigneeId: transaction.closer?.id,
      /* if user changes to internal notary and selects unassigned, it will default back to notarize override if
       * - the transaction is collaborative lender/title
       * - has no closer assigned
       * - has overflow turned on, but hasn't set a max wait time for overflow
       *
       * This is so that the signers of title agencies with BYON + collab do not get stuck waiting for a notary
       * forever. If there is wait time set, then we do not default it back because eventually a NOD
       * will pick it up.
       */
      notarizeCloserOverride:
        transaction.notarizeCloserOverride ||
        (organization.featureList.includes(Feature.ORG_NOTARY_OVERFLOW) &&
          overflowIsTurnedOff(organization.notaryWaitTimeInMinutes) && // this checks that wait time is set, not actually if feature is on/off
          transaction.isCollaborative &&
          !transaction.closer?.id),
      signingScheduleType,
      pointsOfContact: initialPointsOfContact,
      hasPointsOfContact: initialPointsOfContact && initialPointsOfContact.length > 0,
      customerSigners: customerSigners.map((customerSigner) => ({
        ...customerSigner,
        address: customerSigner.address.country ? customerSigner.address : null,
        // if is recipient group, override the email value to be the same as the sharedInboxEmail of the recipientGroup
        // so that form validations for signerDetails pass
        email: customerSigner.recipientGroup
          ? customerSigner.recipientGroup.sharedInboxEmail
          : customerSigner.email,
      })),
      organizationTransactionWitnesses: transaction.organizationTransactionWitnesses,
      personallyKnownToNotary: Boolean(
        transaction.customerSigners.some((customer) => customer.personallyKnownToNotary),
      ),
      // Capitalized because thats the name that the propertyaddress form uses
      EditTransactionPropertyAddress: transaction.propertyAddress
        ? {
            line1: transaction.propertyAddress.line1,
            line2: transaction.propertyAddress.line2,
            city: transaction.propertyAddress.city,
            state: transaction.propertyAddress.state,
            postal: transaction.propertyAddress.postal,
            country: transaction.propertyAddress.country,
            lookup: formattedPropertyAddress(transaction.propertyAddress),
          }
        : null,
    };

    this.state = {
      isSaving: false,
    };
  }

  componentDidMount() {
    const {
      transaction,
      viewer: { user },
      trackDocumentBundleAccessedMutateFn,
    } = this.props;
    trackDocumentBundleAccessedMutateFn({
      variables: {
        input: { documentBundleId: transaction.document_bundle.id, userId: user.id },
      },
    }).catch(captureException);
  }
  handleSave = (data) => {
    const { intl } = this.props;
    this.setState({
      isSaving: true,
    });
    return this.writeUpdateMutation(data)
      .catch((error) => {
        const isGraphError = isGraphQLError(error);
        if (isGraphError) {
          const errorString =
            error.graphQLErrors?.[0]?.message || intl.formatMessage(messages.genericError);
          store.dispatch(addError(errorString, ERROR_TYPES.REGULAR));
        } else {
          captureException(error);
        }
        throw new Error("Error updating transaction"); // throwing error so we don't try to save or send txn
      })
      .finally(() => {
        this.setState({
          isSaving: false,
        });
      });
  };

  segmentAndExit = (segmentTrackEvent, id, returnHomeDirectly = false) => {
    segmentTrack(segmentTrackEvent, {
      organization_transaction_id: id,
      form: "title",
      form_version: 2,
    });
    this.props.navigate(
      returnHomeDirectly
        ? newPathWithPreservedSearchParams(TRANSACTION_PATH)
        : newPathWithPreservedSearchParams("/transaction-success", {
            type: "direct",
          }),
    );
  };

  handleSaveAndClose = (data) => {
    const {
      transaction: { id },
    } = this.props;
    return this.handleSave(data)
      .then(() => {
        this.segmentAndExit(EVENT.ORGANIZATION_TRANSACTION_EDITOR_SAVE_EXIT, id, true);
      })
      .catch((_err) => {}); // ignore error here because it should be handled by handleSave
  };

  handleSend = (data, { closingOpsOverride = false } = {}) => {
    const {
      transaction: { id },
      transaction,
      organization,
      sendOrganizationTransactionMutateFn,
      sendOrganizationTransactionToClosingOpsMutateFn,
      intl,
      withFeeCollab,
      permissions: { hasPermissionFor },
    } = this.props;

    const { titlePlaceOrderEnabled, lenderPlaceOrderEnabled } = this.props;

    let mutation;
    let input;
    let event;
    let genericErrorMessage;
    let exitAfterTrack = false;

    if (titlePlaceOrderEnabled || lenderPlaceOrderEnabled) {
      mutation = sendOrganizationTransactionToClosingOpsMutateFn;
      input = { organizationTransactionId: id };
      event = EVENT.ORGANIZATION_TRANSACTION_EDITOR_SEND_CLOSING_OPS;
      genericErrorMessage = "There was an issue placing an order";
    } else {
      mutation = sendOrganizationTransactionMutateFn;
      input = { id, closingOpsOverride };
      event = EVENT.ORGANIZATION_TRANSACTION_EDITOR_SEND;
      genericErrorMessage = "There was an issue sending the transaction";
      exitAfterTrack = true;
    }

    if (
      organization.paymentSpecified ||
      alternativelyHandledPayment(
        transaction.organization.lenderPartnerPayer,
        organization.defaultPayer,
        withFeeCollab,
      )
    ) {
      this.setState({
        isSaving: true,
      });
      return this.writeUpdateMutation(data)
        .then(() => {
          return mutation({ variables: { input } })
            .then(() => {
              if (exitAfterTrack) {
                this.segmentAndExit(event, id);
              } else {
                segmentTrack(event, {
                  organization_transaction_id: id,
                  form: "title",
                  form_version: 2,
                });
              }
            })
            .catch((errors) => {
              const errorString = errors?.graphQLErrors[0]?.message || genericErrorMessage;
              const errorCode = errors?.graphQLErrors?.[0]?.code;
              if (errorCode === 412 && hasPermissionFor("manageOpenOrders")) {
                return {
                  closingOpsOverrideMessage: errorString,
                };
              }
              store.dispatch(addError(errorString, ERROR_TYPES.REGULAR));
            });
        })
        .catch((error) => {
          if (isGraphQLError(error)) {
            // Currently error.graphQLErrors[0].message is returning "Unprocessible Entity" using a better message
            const errorString = intl.formatMessage(messages.updateError);
            store.dispatch(addError(errorString, ERROR_TYPES.REGULAR));
          } else {
            captureException(error);
          }
        })
        .finally(() => {
          this.setState({
            isSaving: false,
          });
        });
    }
  };

  handleDeleteCustomerSigner = (customerSignerId) => {
    const { deleteOrganizationTranasctionCustomerMutateFn } = this.props;

    return deleteOrganizationTranasctionCustomerMutateFn({
      variables: { input: { id: customerSignerId } },
    }).catch((error) => {
      const errorString = error.graphQLErrors[0].message;
      store.dispatch(addError(errorString, ERROR_TYPES.REGULAR));
      throw new Error("Failed to delete customer signer");
    });
  };

  handleUpdateNotaryInstruction = (instruction) => {
    this.setState({
      isSaving: true,
    });
    const {
      upsertBundleInstructionMutateFn,
      transaction,
      transaction: { id: transactionId },
      organization,
      intl,
      activeOrgId,
    } = this.props;

    return upsertBundleInstructionMutateFn({
      variables: {
        input: {
          documentBundleId: transaction.document_bundle.id,
          text: instruction.text,
          organizationId: activeOrgId,
          documentBundleInstructionId: instruction.id,
        },
      },
      update(cache, { data: { upsertDocumentBundleInstruction } }) {
        if (instruction.id) {
          return;
        }
        const { transaction, ...rest } = cache.readQuery({
          query: TitleTransactionEditQuery,
          variables: {
            transactionId,
            organizationId: organization.id,
            userTagList: TITLE_EDIT_QUERY_USER_TAG_LIST,
          },
        });
        const newTransaction = {
          ...transaction,
          document_bundle: {
            ...transaction.document_bundle,
            instructions: [
              ...transaction.document_bundle.instructions,
              upsertDocumentBundleInstruction.documentBundleInstruction,
            ],
          },
        };
        cache.writeQuery({
          query: TitleTransactionEditQuery,
          variables: {
            transactionId,
            organization: organization.id,
            userTagList: TITLE_EDIT_QUERY_USER_TAG_LIST,
          },
          data: { transaction: newTransaction, ...rest },
        });
      },
    })
      .catch((error) => {
        const isGraphError = isGraphQLError(error);
        if (isGraphError) {
          const errorString =
            error.graphQLErrors?.[0]?.message || intl.formatMessage(messages.genericError);
          store.dispatch(addError(errorString, ERROR_TYPES.REGULAR));
        } else {
          captureException(error);
        }
      })
      .finally(() => {
        this.setState({
          isSaving: false,
        });
      });
  };

  handleDeleteNotaryInstruction = (id) => {
    const { intl } = this.props;
    this.setState({
      isSaving: true,
    });
    const { deleteDocumentBundleInstructionMutateFn } = this.props;

    return deleteDocumentBundleInstructionMutateFn({
      variables: { input: { id } },
    })
      .catch((error) => {
        const isGraphError = isGraphQLError(error);
        if (isGraphError) {
          const errorString =
            error.graphQLErrors?.[0]?.message || intl.formatMessage(messages.genericError);
          store.dispatch(addError(errorString, ERROR_TYPES.REGULAR));
        } else {
          captureException(error);
        }
      })
      .finally(() => {
        this.setState({
          isSaving: false,
        });
      });
  };

  getSendLabel = () => {
    const { titlePlaceOrderEnabled, lenderPlaceOrderEnabled } = this.props;

    if (titlePlaceOrderEnabled || lenderPlaceOrderEnabled) {
      return (
        <FormattedMessage id="292a22f7-df6b-4ac8-8a68-4cc2f209e549" defaultMessage="Place Order" />
      );
    }

    return null;
  };

  // Mutations
  writeUpdateMutation({
    transactionName,
    transactionType,
    entityName,
    secondaryId,
    subjectLine,
    customerNote,
    emailSignature,
    fileNumber,
    loanNumber,
    recordingLocation,
    titleUnderwriter,
    activationDate,
    expirationDate,
    notaryMeetingTime,
    signingScheduleType,
    EditTransactionPropertyAddress,
    pointsOfContact,
    customerSigners,
    organizationTransactionWitnesses,
    recallReason,
    smsAuthenticationRequired,
    notarizeCloserOverride,
    closerAssigneeId,
    personallyKnownToNotary,
    ...otherProps
  }) {
    const {
      updateTransactionMutateFn,
      transaction,
      transaction: { id },
      organization,
    } = this.props;

    // We take the id's from the transaction customerSigners and map them onto the form customerSigners
    const formattedCustomerSigners = customerSigners.map((signer, index) => {
      return {
        id: transaction?.customerSigners?.[index]?.id,
        ...signer,
        personallyKnownToNotary: index === 0 ? Boolean(personallyKnownToNotary) : false,
        // if this is a signing group clear the email value so it is not updated on the server
        ...(signer.recipientGroup ? { email: null } : {}),
      };
    });

    const formattedOrganizationTransactionWitnesses = organizationTransactionWitnesses.map(
      (witness, index) => {
        return {
          ...witness,
          id: witness.id || transaction?.organizationTransactionWitnesses[index]?.id,
        };
      },
    );

    const signingSchedule = serializeSigningSchedule({
      expirationDate,
      signingScheduleType,
      activationDate,
      notaryMeetingTime,
      expirationTimezone: otherProps.expirationTimezone,
    });

    const activationExpiration = {
      activationTime: signingSchedule.activationTime,
      expiry: signingSchedule.expiry,
      activationTimezone: signingSchedule.activationTimezone,
      expiryTimezone: signingSchedule.expiryTimezone,
    };

    const authRequirement =
      organization.defaultAuthenticationRequirement === AuthTypes.SMS
        ? smsAuthenticationRequired
          ? AuthTypes.SMS
          : AuthTypes.NONE
        : transaction.authRequirement;

    const updateMutationPromise = updateTransactionMutateFn({
      variables: {
        input: {
          id,
          organizationId: organization.id,
          transaction: {
            name: transactionName,
            transactionType,
            secondaryIdRequired: secondaryId,
            entityName,
            message: customerNote,
            messageSubject: subjectLine,
            messageSignature: emailSignature,
            recallReason,
            requiredAuth: authRequirement,
            // recordingLocation is not available in "Other" type transactions
            recordingLocationId: recordingLocation,
            titleUnderwriterOrgId: titleUnderwriter,

            // Activation and Expiration
            ...activationExpiration,

            fileNumber,
            loanNumber,
            streetAddress: EditTransactionPropertyAddress
              ? {
                  line1: EditTransactionPropertyAddress.line1,
                  line2: EditTransactionPropertyAddress.line2,
                  city: EditTransactionPropertyAddress.city,
                  state: EditTransactionPropertyAddress.state,
                  postal: EditTransactionPropertyAddress.postal,
                  country: EditTransactionPropertyAddress.country,
                }
              : null,
          },
          contacts: (pointsOfContact || []).map((contact) => ({
            id: contact.id,
            firstName: contact.firstName,
            lastName: contact.lastName,
            phoneNumber: contact.phoneNumber,
            email: contact.email,
            role: contact.role,
            title: contact.title,
            shownToSigner: contact.shownToSigner,
            accessToTransaction:
              contact.accessToTransaction && isHybridTransactionType(transactionType),
          })),
          customers: encodeCustomerSigners(formattedCustomerSigners),
          signingSchedule,
          notarizeCloserOverride: Boolean(notarizeCloserOverride),
          closerAssigneeId:
            notarizeCloserOverride || !closerAssigneeId || closerAssigneeId === UNASSIGNED_CLOSER
              ? null
              : closerAssigneeId,
          witnesses: formattedOrganizationTransactionWitnesses.map((witness) => {
            return {
              id: witness.id,
              email: witness.email,
              phoneNumber: witness.phoneNumber,
              firstName: witness.firstName,
              middleName: witness.middleName,
              lastName: witness.lastName,
            };
          }),
        },
      },
    });
    return updateMutationPromise.then((result) => {
      const { customerSigners } =
        result.data.updateOrganizationTransactionV2.organization_transaction;
      if (!isEqual(formattedCustomerSigners, customerSigners)) {
        otherProps.change("customerSigners", customerSigners);
      }
      return result;
    });
  }

  render() {
    if (!this.state || !this.initTransactionData) {
      return null;
    }

    const { isSaving } = this.state;
    const {
      transaction,
      mutationLoading,
      viewer,
      organization,
      titlePlaceOrderEnabled,
      lenderPlaceOrderEnabled,
      navigate,
      withFeeCollab,
      permissions: { hasPermissionFor },
    } = this.props;

    const usersOrgCreatedTransaction =
      organization.id === transaction.organization.id ||
      Boolean(
        organization.subsidiaryOrganizations.find((o) => o.id === transaction.organization.id),
      );

    return (
      <div>
        {isSaving && <LoadingIndicator />}
        <TransactionEditForm
          initialData={this.initTransactionData}
          transaction={transaction}
          viewer={viewer}
          organization={organization}
          onSaveAndClose={this.handleSaveAndClose}
          onSend={this.handleSend}
          onSave={this.handleSave}
          disabledSubmit={mutationLoading}
          onDeleteCustomerSigner={this.handleDeleteCustomerSigner}
          onUpdateNotaryInstruction={this.handleUpdateNotaryInstruction}
          onDeleteNotaryInstruction={this.handleDeleteNotaryInstruction}
          titlePlaceOrderEnabled={titlePlaceOrderEnabled}
          lenderPlaceOrderEnabled={lenderPlaceOrderEnabled}
          sendLabel={this.getSendLabel()}
          isClosingOps={hasPermissionFor("manageOpenOrders")}
          usersOrgCreatedTransaction={usersOrgCreatedTransaction}
          navigate={navigate}
          withFeeCollab={withFeeCollab}
        />
      </div>
    );
  }
}

function OrganizationTransactionMutations(props) {
  const sendOrganizationTransactionMutateFn = useMutation(SendOrganizationTransactionMutation);
  const [
    sendOrganizationTransactionToClosingOpsMutateFn,
    { loading: sendOrganizationTransactionToClosingOpsLoading },
  ] = useRawMutation(SendOrganizationTransactionToClosingOpsMutation);
  const [updateTransactionMutateFn, { loading: updateTransactionMutationLoading }] = useRawMutation(
    UpdateTitleOrgTransactionMutation,
  );
  const [upsertBundleInstructionMutateFn, { loading: upsertBundleInsructionLoading }] =
    useRawMutation(UpsertDocumentBundleInstructionMutation);
  const [deleteDocumentBundleInstructionMutateFn, { loading: deleteBundleInstructionLoading }] =
    useRawMutation(DeleteDocumentBundleInstructionMutation);
  const [
    deleteOrganizationTranasctionCustomerMutateFn,
    { loading: deleteOrganizationTranasctionCustomerLoading },
  ] = useRawMutation(DeleteOrganizationTranasctionCustomerMutation);
  return props.children({
    sendOrganizationTransactionMutateFn,
    updateTransactionMutateFn,
    upsertBundleInstructionMutateFn,
    deleteDocumentBundleInstructionMutateFn,
    deleteOrganizationTranasctionCustomerMutateFn,
    sendOrganizationTransactionToClosingOpsMutateFn,
    organizationTransactionMutationLoading:
      updateTransactionMutationLoading ||
      upsertBundleInsructionLoading ||
      deleteBundleInstructionLoading ||
      deleteOrganizationTranasctionCustomerLoading ||
      sendOrganizationTransactionToClosingOpsLoading,
  });
}

function WithV3Banner(props) {
  return useTransactionCreationV3(props.user) ? (
    <Navigate replace to={transactionEditRouteV3(props.transactionId)} />
  ) : (
    props.children
  );
}

function TransactionDetailsRedirect(props) {
  const {
    transaction: { editable, pointsOfContact, transactionType, id },
    viewer: { user },
  } = props.data;

  if (!editable) {
    let route = transactionDetailsRoute(id);

    const isPointOfContactOnTransaction = pointsOfContact.some((poc) => user.id === poc.userId);
    if (isHybridTransactionType(transactionType) && isPointOfContactOnTransaction) {
      route = `/access/transaction/${id}`;
    }

    return <Navigate replace to={route} />;
  }

  return props.children;
}

function TransactionContainerInner({ data, activeOrgId }) {
  const trackDocumentBundleAccessedMutateFn = useMutation(TrackDocumentBundleAccessedMutation);
  const navigate = useNavigate();
  const permissions = usePermissions();
  const { hasPermissionFor } = permissions;
  const intl = useIntl();

  const {
    transaction: { lenderPlaceOrderEnabled, isCollaborative, organization, titleAgency },
    organization: { placeAnOrderEnabled, defaultPayer },
  } = data;
  const lenderTitleFeeCollabEnabled = organization.lenderTitleFeeCollabEnabled;

  // In the event that the title agency does not have the flag enabled, we still need to show the place an order UI
  // when they are editing a transaction that was initiated by another org
  const titlePlaceOrderEnabled = Boolean(
    placeAnOrderEnabled && !hasPermissionFor("manageOpenOrders"),
  );

  const isCollaboratingAgency = isCollaborative && titleAgency?.id === activeOrgId;

  return (
    <OrganizationTransactionMutations>
      {({
        sendOrganizationTransactionMutateFn,
        updateTransactionMutateFn,
        upsertBundleInstructionMutateFn,
        deleteDocumentBundleInstructionMutateFn,
        deleteOrganizationTranasctionCustomerMutateFn,
        sendOrganizationTransactionToClosingOpsMutateFn,
        organizationTransactionMutationLoading,
      }) => {
        return (
          <>
            {!hasPermissionFor("manageOpenOrders") &&
              lenderTitleFeeCollabEnabled &&
              isCollaboratingAgency && (
                <InvoiceModal
                  transactionId={data.transaction.id}
                  lenderPartnerPayer={organization.lenderPartnerPayer}
                  titlePayer={defaultPayer}
                />
              )}
            <TransactionContentContainer
              activeOrgId={activeOrgId}
              transaction={data.transaction}
              viewer={data.viewer}
              organization={data.organization}
              updateTransactionMutateFn={updateTransactionMutateFn}
              mutationLoading={Boolean(organizationTransactionMutationLoading)}
              upsertBundleInstructionMutateFn={upsertBundleInstructionMutateFn}
              deleteDocumentBundleInstructionMutateFn={deleteDocumentBundleInstructionMutateFn}
              sendOrganizationTransactionMutateFn={sendOrganizationTransactionMutateFn}
              trackDocumentBundleAccessedMutateFn={trackDocumentBundleAccessedMutateFn}
              deleteOrganizationTranasctionCustomerMutateFn={
                deleteOrganizationTranasctionCustomerMutateFn
              }
              sendOrganizationTransactionToClosingOpsMutateFn={
                sendOrganizationTransactionToClosingOpsMutateFn
              }
              intl={intl}
              navigate={navigate}
              placeAnOrderEnabled={placeAnOrderEnabled}
              titlePlaceOrderEnabled={titlePlaceOrderEnabled}
              lenderPlaceOrderEnabled={lenderPlaceOrderEnabled}
              withFeeCollab={lenderTitleFeeCollabEnabled}
              permissions={permissions}
            />
          </>
        );
      }}
    </OrganizationTransactionMutations>
  );
}

function TransactionContainer() {
  const [activeOrgId] = useActiveOrganization();
  const { transactionId } = useParams();

  return (
    <QueryWithLoading
      query={TitleTransactionEditQuery}
      variables={{
        transactionId,
        organizationId: activeOrgId,
        userTagList: TITLE_EDIT_QUERY_USER_TAG_LIST,
      }}
    >
      {({ data, loading, error }) => {
        // QueryWithLoading only loads for the first load and so we need this to catch subsequent loads with a different transaction id
        if (loading) {
          return <LoadingIndicator />;
        }

        if (!data?.transaction) {
          return <TransactionErrorRedirect error={error} />;
        }

        return (
          <TransactionDetailsRedirect data={data}>
            <WithV3Banner transactionId={transactionId} user={data.viewer.user}>
              <TransactionContainerInner data={data} activeOrgId={activeOrgId} />
            </WithV3Banner>
          </TransactionDetailsRedirect>
        );
      }}
    </QueryWithLoading>
  );
}

export default TransactionContainer;
